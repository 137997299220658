<template>
  <b-container class='mt-4'>
    <b-spinner v-if='loading'></b-spinner>
    <div v-else>
      <b-row>
        <b-col class='d-flex align-items-center'>
          <BackButton></BackButton>
          <h5 class='ml-2'>Generic Importer Settings for client
            <b-link v-if='client' :to='`/onboarding/client/edit/${client.id}`'>{{ client.display_name }}</b-link>
          </h5>
        </b-col>
        <b-col class='d-flex justify-content-end'>
          <b-button class='ml-3' @click='submit' type='button' :disabled='saving' id='btn_submit' variant='primary'>
            <b-spinner label='Loading...' variant='light' small v-if='saving'></b-spinner>
            <span v-else>Create configuration</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class='mt-4'>
        <b-col>
          <b-card class='mb-4'>
            <template #header>
              <h6 class='mb-0'>Add new <b>{{ fileTypeDetails.name.toLowerCase() }}</b> file configuration</h6>
            </template>

            <b-alert v-if="uniqueIdConfigs" show>
              The following columns will be used to generate the Unique ID: {{ uniqueIdConfigs.join(', ') }}
            </b-alert>
            <b-alert v-else variant="warning" show>
              No unique key configured for this client. The ingestion will not work if the config key is not configured.
            </b-alert>

            <FileConfiguration
              :fileTypeDetails="fileTypeDetails"
              :filepaths="filepaths"
              :eligibilityProviders="providers.filter(p => p.type === 'eligibility').map(p => {
                return {
                  'id': p.id,
                  'filepath': p.filepath
                }
              })"
              :readOnly="false"
              :uniqueIdConfigs="uniqueIdConfigs"
              :configuration="configuration"
              @form-validation="($ev) => formValidation($ev)"/>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>

import BackButton from '@/components/BackButton.vue';
import FileConfiguration from '@/views/Onboarding/GenericImporter/FileConfiguration.vue';

const R = require('ramda');

export default {
  components: { FileConfiguration, BackButton },
  data() {
    return {
      saving: false,
      loading: true,
      client: null,
      uniqueIdConfigs: [],
      fileTypeDetails: {},
      providers: [],
      configuredFilepaths: [],
      filepaths: [],
      configuration: {
        delimiter: null,
        external_unique_id_column_number: null,
        provider_id: null,
        mapping: [],
        normalizers: {
          relationship: [],
          gender: [],
          date: [],
        },
        genericEligibilityMapping: [],
      },
      isFormValid: null,
    };
  },
  async beforeMount() {
    await this.loadPage();
  },
  methods: {
    async loadPage() {
      this.loading = true;
      const clients = await this.$store.dispatch('Core/fetchClients');
      this.client = clients.find(c => Number(c.id) === Number(this.$route.params.clientId));
      await Promise.all([
        this.getClientConfigs(this.$route.params.clientId),
        this.getClientConfigurations(this.$route.params.clientId),
        this.getFileTypeDetails(this.$route.params.fileTypeId),
        this.getProviders(),
      ]);

      this.providers.forEach(p => {
        // if filepath is not already in use and matches the pattern for this file type
        if (!this.configuredFilepaths.includes(p.filepath) && p.filepath.match(this.fileTypeDetails.filepath)) {
          this.filepaths.push({
            label: p.filepath,
            id: p.id,
          });
        }
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const e of this.fileTypeDetails.file_type_schema) {
        const obj = {
          ...R.omit([ 'uuid' ], e),
          column_number: null,
          ifts_uuid: e.uuid,
        };
        this.configuration.mapping.push(obj);
      }

      this.loading = false;
    },
    async getClientConfigs(clientId) {
      const res = await this.$store.dispatch('Sftp/GenericImporter/getClientImporters', { clientId });
      this.uniqueIdConfigs = res?.data?.unique_id_fields || [];
      this.configuredFileTypes = res?.data?.configurations || [];
    },
    async getFileTypeDetails(id) {
      const res = await this.$store.dispatch('Sftp/GenericImporter/getGenericImporterConfigs', id);
      this.fileTypeDetails = res?.data || {};
    },
    async getProviders() {
      const res = await this.$store.dispatch('Sftp/Provider/getProvidersList');
      this.providers = res?.data || [];
    },
    async getClientConfigurations(clientId) {
      const res = await this.$store.dispatch('Sftp/GenericImporter/getClientImporters', { clientId });
      this.uniqueIdConfigs = res?.data?.unique_id_fields || [];
      this.configuredFilepaths = res?.data?.configurations
        ?.filter(c => c.file_type_id === Number(this.$route.params.fileTypeId))
        .map(c => c.filepath) || [];
    },
    formValidation(formValidation) {
      this.isFormValid = formValidation;
    },
    async submit() {
      if (!this.isFormValid) {
        this.$noty.error('Please fill all required fields');
        return;
      }

      try {
        this.loading = true;
        await this.$store.dispatch('Sftp/GenericImporter/createGenericImportClientConfiguration', {
          importerId: this.$route.params.fileTypeId,
          onboardingClientId: this.$route.params.clientId,
          data: {
            mapping: this.configuration.mapping.map(m => ({
              field_id: m.ifts_uuid,
              column_number: Number(m.column_number),
            })),
            normalizers: {
              relationship: this.configuration.normalizers.relationship,
              date: this.configuration.normalizers.date,
              gender: this.configuration.normalizers.gender,
            },
            delimiter: String.fromCharCode(this.configuration.delimiter),
            external_unique_id_column_number: this.configuration.external_unique_id_column_number,
            file_type_id: this.$route.params.fileTypeId,
            provider_id: this.configuration.provider_id,
            genericEligibilityMapping: this.configuration.genericEligibilityMapping,
          },
        });

        this.$noty.success('Configuration created with success');
        this.$router.push({
          name: 'ClientGenericImporterSettings',
          params: {
            clientId: Number(this.$route.params.clientId),
          },
        });
      } catch (err) {
        console.error(err);
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
